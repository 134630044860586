import React from "react";
import {ButtonIconEdit} from "../../../Components/Inputs";
import {HtmlDisplay} from "../../../Components/Display";
import {locations} from "../../../Utilities/Location";
import PropTypes from "prop-types";
import {ButtonIconView} from "../../../Components/Inputs/Buttons/ButtonIcon/ButtonIcon";
import {
    getCompleteReadingMediumsTextByValue,
    getEligibilityTextByValue,
    getGradeLevelTextByValue,
    getStudentApprovalStatusTextByValue,
    isCensusMonthAndNotAdmin
} from "../studentUtilities";

const StudentApprovalRow = ({redirect, student}) => {

    const canEditUser = !isCensusMonthAndNotAdmin();

    /* const handleOnChangeApprovalStatus = (event, studentId) => {
         event.preventDefault();
         onApprovalStatusClick(studentId, event.target.value);
     };
     const canApprove = allow(policyEvents.APPROVE_STUDENTS);
     */

    const handleEditStudentDetailsClick = (event, studentId) => {
        event.preventDefault();
        redirect(locations.EDIT_STUDENT.getPath(studentId));
    };


    return (
        <tr id={student.studentId} data-testid={student.studentId}>
            <td>
                {student.fullName}
            </td>
            <td>
                {student.dateOfBirth}
            </td>
            <td>
                {student.dateCreatedUtc || ``}
            </td>
            <td>
                {student.dateModifiedUtc || ``}
            </td>
            <td>
                {getGradeLevelTextByValue(student.gradeLevel)}
            </td>
            <td>
                {getCompleteReadingMediumsTextByValue(student.primaryReadingMedium)}
            </td>
            <td>
                {student.districtName || ``}
            </td>
            <td>
                {student.userFullName || ``}
            </td>
            <td className={student.studentApprovalStatusName}>
                {/* {
                    canApprove &&
                    <SelectField
                        name={`approval-status-${student.studentId}`}
                        value={student.studentApprovalStatus}
                        includeDefaultOption={false}
                        options={STUDENT_APPROVAL_STATUS_LIST}
                        onChange={(event) => handleOnChangeApprovalStatus(event, student.studentId)}
                        label={`Approval Status for ${student.firstName}`}
                        showOnlyForSr={true}
                    />
                }
                {
                    !canApprove &&*/}
                <HtmlDisplay
                    html={getStudentApprovalStatusTextByValue(student.studentApprovalStatus)}
                    name={`approval-status-${student.studentId}`}/>
            </td>

            <td>
                {getEligibilityTextByValue(student.eligibility || ``)}
            </td>
            <td className={`align-center`}>
                {
                    canEditUser &&
                    <ButtonIconEdit
                        inputDescription={`for ${student.firstName} ${student.lastName}`}
                        onClick={(event) => handleEditStudentDetailsClick(event, student.studentId)}
                    />
                }
                {
                    !canEditUser &&
                    <ButtonIconView
                        inputDescription={`for ${student.firstName} ${student.lastName}`}
                        onClick={(event) => handleEditStudentDetailsClick(event, student.studentId)}
                    />
                }
            </td>
        </tr>
    );
};

StudentApprovalRow.PropType = {
    redirect: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
}

export default StudentApprovalRow;