import {getStudentApprovalStatusName} from "../studentConstants";
import {convertToDate} from "../../../Utilities/Types/dateUtilities";

export const studentModel = ({
                                 dateCreatedUtc,
                                 dateModifiedUtc,
                                 dateOfBirth,
                                 districtId,
                                 districtName,
                                 eligibility,
                                 fimcStudentId,
                                 firstName = "",
                                 fullName = "",
                                 gradeLevel,
                                 gradeLevelDescription,
                                 language,
                                 lastName = "",
                                 primaryReadingMedium,
                                 studentApprovalStatus,
                                 studentId,
                                 userFullName,
                             } = {}) => ({
    dateCreatedUtc,
    dateCreatedinDateFormat: convertToDate(dateCreatedUtc),
    dateModifiedUtc,
    dateModifiedinDateFormat: convertToDate(dateModifiedUtc),
    dateOfBirth,
    dateOfBirthInDateFormat: convertToDate(dateOfBirth),
    districtId,
    districtName,
    eligibility,
    fimcStudentId,
    firstName,
    fullName,
    gradeLevel,
    gradeLevelDescription,
    language,
    lastName,
    primaryReadingMedium,
    studentApprovalStatus,
    studentApprovalStatusName: getStudentApprovalStatusName(studentApprovalStatus),
    studentId,
    userFullName,
});

export const approveStudentModel = ({
                                        studentId,
                                        studentApprovalStatus,
                                        firstName,
                                        lastName
                                    } = {}) => ({
    id: studentId,
    studentApprovalStatus,
    firstName,
    lastName
});

export const approveAndSearchStudentsModel = ({
                                                  students,
                                                  searchFilters,
                                              } = {}) => ({
    students: students,
    searchFilters: searchFilters
});

export const studentApprovalUpdateResultsModel = ({
                                                      errors,
                                                      numberUpdated,
                                                      filteredStudents
                                                  } = {}) => ({
    errors,
    numberUpdated,
    filteredStudents
});