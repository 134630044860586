import React from "react";
import PropTypes from "prop-types";
import {HtmlDisplay} from "../../Components/Display";
import {Button, ButtonLinkStyle} from "../../Components/Inputs";
import {GridCell, GridContainer, GridTable} from "../../Components/Grid";
import {Allow, policyEvents} from "../../Components/Authorize";
import {isGuidEmpty} from "../../Utilities/commonUtilities";

export const LibraryCatalogItem = ({
                                       catalogId,
                                       description,
                                       details,
                                       formats,
                                       handleAddToQueue,
                                       handleDelete,
                                       handleEdit,
                                       title,
                                   }) => {

    return <>
        <GridContainer className={`catalog__item`} hasMarginX>
            <GridCell>
                <h3 id={`title${catalogId}`}>{title}</h3>

                <HtmlDisplay html={description}/>

                <HtmlDisplay html={details}/>
                {
                    handleDelete &&
                    <Button isCollapsed label={`Remove`} name={`btnDelete${catalogId}`} onClick={handleDelete}/>
                }
                {
                    formats &&
                    <GridTable>
                        <thead>
                        <tr>
                            <th>Format</th>
                            <th colSpan={2}>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            formats.map(format => {
                                const suffix = `${format.catalogId}`;
                                const formatName = `${format.format}`;
                                const key = `${title} ${formatName}`;
                                const handleClickEdit = () => handleEdit(format.catalogId);
                                const handleClickAddToQueue = () => handleAddToQueue(format.catalogId);
                                const isEmptyGuid = isGuidEmpty(format.catalogId);

                                return <tr key={key}>
                                    <td>{format.format} </td>
                                    <td>
                                        {
                                            !isEmptyGuid &&
                                            <ButtonLinkStyle isCollapsed isPrimary label={`Add to Cart`}
                                                             name={`btnAddQueue${suffix}`}
                                                             onClick={handleClickAddToQueue}/>
                                        }
                                    </td>
                                    <td>
                                        {
                                            !isEmptyGuid &&
                                            <Allow policyEvent={policyEvents.MANAGE_CATALOG}>
                                                <ButtonLinkStyle isCollapsed label={`Edit Item`}
                                                                 name={`btnEdit${suffix}`}
                                                                 onClick={handleClickEdit}/>
                                            </Allow>
                                        }
                                    </td>
                                </tr>
                            })
                        }
                        </tbody>
                    </GridTable>
                }

            </GridCell>
        </GridContainer>
    </>;
};

LibraryCatalogItem.propTypes = {
    description: PropTypes.string,
    details: PropTypes.string,
    catalogId: PropTypes.string,
    formats: PropTypes.array,
    handleAddToQueue: PropTypes.func,
    handleDelete: PropTypes.func,
    handleEdit: PropTypes.func,
    title: PropTypes.string,
};