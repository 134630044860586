import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {PageTitle} from "../../Components/Landmarks";
import {LayoutFullBleed} from "../../Components/Layout";
import {Button, TextField} from "../../Components/Inputs";
import {GridCell, gridConstants, GridContainer} from "../../Components/Grid";
import {LibraryCatalogItem} from "./LibraryCatalogItem";
import libraryCatalogApi from "./libraryCatalogApi";
import {catalogItemModel} from "./libraryFactory";
import {useInput} from "../../Components/Inputs/inputUtility";
import {filterCatalog} from "./libraryUtility";
import {LinkButton} from "../../Components/Link";
import {locations} from "../../Utilities/Location";
import {Allow, policyEvents} from "../../Components/Authorize";
import {isArrayNullOrEmpty} from "../../Utilities/Types/arrayUtilities";
import libraryQueueApi from "./libraryQueueApi";

export const LibraryCatalogContainer = ({
                                            handleApiCall,
                                            redirect,
                                        }) => {
    const [resources, setResources] = useState([]);
    const [filteredResources, setFilteredResources] = useState([]);
    const keywordSearch = useInput("");

    const handleEdit = (id) => {
        const path = locations.LIBRARY_ITEM.getLink(id).to;
        redirect(path);
    }

    const handleSearchCatalog = () => {
        const result = filterCatalog(resources, keywordSearch.value) || [];
        setFilteredResources(result);
    }

    const handleAddToQueue = async (catalogId) => {
        handleApiCall(() => libraryQueueApi.addToQueue(catalogId));
    }

    useEffect(() => {
        const getResources = () => {
            handleApiCall(libraryCatalogApi.getResources, (results) => setResources(results));
        }

        getResources();

    }, []);

    useEffect(() => {
        handleSearchCatalog();
    }, [resources, keywordSearch.value]);

    return <>
        <PageTitle h1={`Library Catalog`}/>

        <div className={`searchBox`}>
            <div className={`searchBox__title`}>Search the catalog</div>
            <GridContainer hasMarginX hasMarginY className={`searchBox__input`}>
                <TextField labelClass={`show-for-sr`} name={`txtSearch`} label={`Search`} placeholder={`keyword`}
                           medium_columns={gridConstants.column.FIVE} large_columns={gridConstants.column.SEVEN}
                           {...keywordSearch} />
                <GridCell large_columns={gridConstants.column.TWO} medium_columns={gridConstants.column.TWO}>
                    <Button label={`Search`} name={`btnSearch`} onClick={handleSearchCatalog} isPrimary/>
                </GridCell>
                <GridCell large_columns={gridConstants.column.TWO} medium_columns={gridConstants.column.THREE}>
                    <LinkButton {...locations.LIBRARY_WHATS_NEW.link} isExpanded/>
                </GridCell>
                <GridCell large_columns={gridConstants.column.ONE} medium_columns={gridConstants.column.TWO}>
                    <LinkButton {...locations.LIBRARY_QUEUE.link} isExpanded/>
                </GridCell>
                <Allow policyEvent={policyEvents.MANAGE_CATALOG}>
                    <GridCell className={`text-center margin--topNone margin--bottomNone`}>
                        <hr className={`margin--topNone`}/>
                        <LinkButton {...locations.LIBRARY_ITEM.link} label={`Add Library Item`}/>
                        <LinkButton {...locations.LIBRARY_PUBLISHER.link} />
                        <LinkButton {...locations.LIBRARY_VENDOR.link} />
                    </GridCell>
                </Allow>
            </GridContainer>
        </div>


        <LayoutFullBleed className={`catalog`}>
            <header>
                <h2>Search Results</h2>
            </header>

            {
                filteredResources.map((resource) => {
                    const item = catalogItemModel(resource);
                    return <LibraryCatalogItem
                        key={item.catalogId}
                        handleAddToQueue={handleAddToQueue}
                        handleEdit={handleEdit}
                        {...item}
                    />;
                })
            }
            {
                isArrayNullOrEmpty(filteredResources) &&
                <p className={`text-center`}>No results.</p>
            }

        </LayoutFullBleed>
    </>;
};

LibraryCatalogContainer.propTypes = {
    handleApiCall: PropTypes.func.isRequired,
    redirect: PropTypes.func.isRequired,
};